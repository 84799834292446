export const DEFAULT_TEXT_CONFIG = {
  fontFamily: 'Default',
  color: 'rgba(255, 255, 255, 1)',
  fontSize: 30,
  fontStyle: 'Default',
  textOutlineWidth: 1,
  textOutlineColor: 'rgba(0, 0, 0, 1)',
  mask: false,
  top: 0,
  StickerTop: 50,
  StickerLeft: 50,
  StickerSize: 5,
};
