/* eslint-disable */
import * as React from 'react';
import { useEffect } from 'react';
import imgJson from '../../assets/images.json';
import styled from 'styled-components';
import BannerImage from './BannerImage';

const BannerImageListContainer = styled.div`
  position: relative;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 10px;
  padding: 5px;
  justify-items: center;
  align-items: center;
  margin-top: 10px;
  max-height: 600px;
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-width: thin;
  scrollbar-color: #ccc #fff;
  width: 100%;
`;

const Container = styled.div`
  font-family: Arial, sans-serif;
  z-index: 1;
  & > * {
    width: 100%;
  }
`;

export default function Banners(props: any) {
  const { setbannerimage, bannerSelect }: any = props;
  const [bannerImages, setbannerimages] = React.useState(imgJson['Kolory']);
  const [value, setValue] = React.useState(0);

  useEffect(() => {
    //@ts-ignore
    setbannerimages(imgJson[bannerSelect] ? imgJson[bannerSelect] : imgJson['Kolory']);
  }, [bannerSelect]);

  useEffect(() => {
    setbannerimage(require(`../../assets/banners/${bannerImages[0].name + '.jpg'}`));
  }, [bannerSelect]);

  const onClick = (image: string, index: number) => {
    setbannerimage(image);
    setValue(index);
  };

  return (
    <Container>
      <BannerImageListContainer>
        {bannerImages.map((bannerImage: any, index: number) => (
          <BannerImage
            bannerImage={bannerImage.name + '.jpg'}
            key={index}
            onClick={(image: any) => onClick(image, index)}
            heighlight={value === index}
            id={index}
          />
        ))}
      </BannerImageListContainer>
    </Container>
  );
}
