import './App.css';
import CharacterBanner from './Components/Main';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import BannerPdf from './Components/Result/BannerPdf';
import { useEffect } from 'react';
import fonts from './assets/font_mapping.json';

function App() {
  useEffect(() => {
    fonts.fonts.forEach((font) => {
      const style = document.createElement('style');
      style.appendChild(
        document.createTextNode(`
          @font-face {
            font-family: "${font.name}";
            src: url(${require(`./assets/Fonts/${font.path}`)}) format("truetype");
          }
        `)
      );
      document.head.appendChild(style);
    });
  }, []);
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<CharacterBanner />} />
        <Route path="/download" element={<BannerPdf />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
