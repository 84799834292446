import React, { useEffect, useState } from 'react';
import BannerResult from './BannerResult';

export default function BannerPdf() {
  const [style, setStyle] = useState({
    fontFamily: '',
    color: '',
    fontSize: 16,
    fontStyle: '',
    textOutlineWidth: 0,
    textOutlineColor: '',
    mask: false,
    characters: '',
    top: 50,
    StickerTop: 50,
    StickerLeft: 50,
    StickerSize: 5,
  });

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    setStyle({
      fontFamily: params.get('fontFamily') || '',
      color: params.get('color') || '',
      fontSize: parseInt(params.get('fontSize') || '16'),
      fontStyle: params.get('fontStyle') || '',
      textOutlineWidth: parseInt(params.get('textOutlineWidth') || '0'),
      textOutlineColor: params.get('textOutlineColor') || '',
      mask: params.get('mask') === 'true' ? true : false,
      characters: params.get('characters') || '',
      top: parseInt(params.get('top') || '50'),
      StickerTop: parseInt(params.get('StickerTop') || '50'),
      StickerLeft: parseInt(params.get('StickerLeft') || '50'),
      StickerSize: parseInt(params.get('StickerSize') || '10'),
    });
  }, []);

  const [bannerimage, setbannerimage] = useState('' as any);
  const [stickerimage, setstickerimage] = useState('' as any);

  useEffect(() => {
    setbannerimage(localStorage.getItem('bannerimage'));
    setstickerimage(localStorage.getItem('stickerimage'));
  }, []);

  return (
    <BannerResult
      characters={style.characters}
      bannerimage={bannerimage}
      stickerimage={stickerimage}
      textconfig={style}
      url={true}
    />
  );
}
