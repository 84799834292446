import React from 'react';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import styled from 'styled-components';
import fonts from '../../assets/font_mapping.json';

const MenuItemStyled = styled(MenuItem)<{ fontFamily: string }>`
  font-family: ${(props) => props.fontFamily};
`;

export default function FontSelect(props: any) {
  const { handlechange } = props;

  return (
    <TextField
      select
      defaultValue={fonts.fonts[0].label}
      label="Font Family"
      onChange={(e) => handlechange(e, 'fontFamily')}
      SelectProps={{
        MenuProps: {
          style: {
            maxHeight: 400,
          },
        },
      }}
    >
      {fonts.fonts.map((option) => (
        <MenuItemStyled key={option.label} value={option.label} fontFamily={option.fontFamily}>
          {option.label}
        </MenuItemStyled>
      ))}
    </TextField>
  );
}
