import styled from 'styled-components';
import React from 'react';

interface CharacterTextProps {
  text: string;
  textconfig: {
    fontFamily: any;
    color: string;
    fontSize: number;
    fontStyle: string;
    textOutlineWidth: number;
    textOutlineColor: string;
    maskImage: string;
    mask: boolean;
    top: number;
  };
}

const CharacterText = styled.span<CharacterTextProps>`
  // position: absolute;
  // top: ${(props) => props.textconfig.top}%;
  // left: 50%;
  // transform: translate(-50%, -50%);
  margin-bottom: ${(props) => props.textconfig.top}rem;
  font-family: ${(props) => props.textconfig.fontFamily} !important;
  color: ${(props) => props.textconfig.color};
  font-size: ${(props) => props.textconfig.fontSize}rem;
  font-style: ${(props) => props.textconfig.fontStyle};
  text-align: center;
  -webkit-text-stroke: ${(props) => props.textconfig.textOutlineWidth}px
    ${(props) => props.textconfig.textOutlineColor};
`;

function CustomCharacterText({ text, textconfig }: CharacterTextProps) {
  return (
    <CharacterText textconfig={textconfig} text={text} id={'me'}>
      {text}
    </CharacterText>
  );
}

export default CustomCharacterText;
