import React, { useState } from 'react';
import styled from 'styled-components';
import CharacterTextStyleSelector from './Text/CharacterTextStyleSelector';
import TextField from '@mui/material/TextField';
import BannerResult from './Result/BannerResult';
import Banners from './BannerImage/Banners';
import { DEFAULT_TEXT_CONFIG } from '../utils/Constants';
import DropdownTabComponent from './SubComponents/DropdownTabComponent';
import axios from 'axios';

const CharacterBannerMain = styled.div`
  text-align: center;
  margin: 0 auto;
`;

const DownlopadButton = styled.button<{ disable: boolean }>`
  background-color: #fff;
  color: #000;
  display: flex;
  justify-content: center;
  align-items: center;

  border: 1px solid #000;
  border-radius: 5px;
  padding: 10px 30px;
  text-align: center;
  text-decoration: none;
  font-size: 16px;

  color: ${(props) => (props.disable ? '#ccc' : '#000')};
  border: ${(props) => (props.disable ? '1px solid #ccc' : '1px solid #000')};
  cursor: ${(props) => (props.disable ? 'not-allowed' : 'pointer')};

  &:disabled {
    background-color: #ccc;
    color: #000;
    border: 1px solid #000;
    border-radius: 5px;
    padding: 10px 30px;
    text-align: center;
    text-decoration: none;
    font-size: 16px;
    cursor: not-allowed;
  }
`;

const CharacterBannerContainer = styled.div`
  text-align: center;
  max-width: 1200px;
  padding: 20px;
  margin: 0 auto;
`;

const CharacterInput = styled(TextField)`
  width: 100%;
  margin-right: 10px; /* Add margin to separate the button and input */
`;

const CharacterInputContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: stretch; /* Align items in a way that they stretch to fill the container */
  margin-bottom: 10px;
  gap: 10px;
`;

const Spinner = styled.div<{ display: boolean }>`
  border: 2px solid #f3f3f3;
  border-top: 2px solid #000;
  border-radius: 50%;
  width: 15px;
  display: ${(props) => (props.display ? 'block' : 'none')};
  height: 15px;
  animation: spin 2s linear infinite;
  margin-right: 10px;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }
`;

const BannerContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 3fr;
  grid-gap: 20px;
  width: 100%;
`;

function CharacterBanner() {
  const [character, setCharacter] = useState('');
  const [bannerSelect, setBannerSelect] = useState('eleganckie' as any);
  const [bannerImage, setbannerimage] = useState('' as any);
  const [stickerimage, setstickerimage] = useState('' as any);
  const [textconfig, settextconfig] = useState(DEFAULT_TEXT_CONFIG);
  const [loadingDownload, setLoadingDownload] = useState(false);

  const handleCharacterChange = (event: any) => {
    setCharacter(event.target.value);
  };

  const onDownload = async () => {
    try {
      setLoadingDownload(true);
      const pdf = await axios.post(
        'https://hidanz.tech/generator-pdf/generate-pdf',
        {
          link: `https://generatorgirland.eduzabawy.com/download?fontFamily=${textconfig.fontFamily}&color=${textconfig.color}&fontSize=${textconfig.fontSize}&fontStyle=${textconfig.fontStyle}&textOutlineWidth=${textconfig.textOutlineWidth}&textOutlineColor=${textconfig.textOutlineColor}&mask=${textconfig.mask}&characters=${character}&top=${textconfig.top}&StickerTop=${textconfig.StickerTop}&StickerLeft=${textconfig.StickerLeft}&StickerSize=${textconfig.StickerSize}`,
          stickerImage: stickerimage,
          bannerImage: bannerImage,
        },
        {
          responseType: 'blob', // Set the response type to blob
        }
      );

      const blob = new Blob([pdf.data], { type: 'application/pdf' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'eduzabawy-banner.pdf';
      a.click();

      setLoadingDownload(false);
    } catch (error) {
      setLoadingDownload(false);
    }

    // localStorage.setItem('bannerimage', bannerImage);
    // localStorage.setItem('stickerimage', stickerimage);
    // window.open(
    //   `/download?fontFamily=${textconfig.fontFamily}&color=${textconfig.color}&fontSize=${textconfig.fontSize}&fontStyle=${textconfig.fontStyle}&textOutlineWidth=${textconfig.textOutlineWidth}&textOutlineColor=${textconfig.textOutlineColor}&mask=${textconfig.mask}&characters=${character}&top=${textconfig.top}&StickerTop=${textconfig.StickerTop}&StickerLeft=${textconfig.StickerLeft}&StickerSize=${textconfig.StickerSize}`,
    //   'width=800,height=600,'
    // );
  };

  return (
    <CharacterBannerMain>
      <CharacterBannerContainer>
        <CharacterInputContainer>
          <CharacterInput
            type="text"
            placeholder="Enter a character"
            value={character}
            onChange={handleCharacterChange}
          />
          <DownlopadButton
            disable={loadingDownload}
            onClick={onDownload}
            disabled={character.length === 0 || bannerImage.length === 0}
          >
            <Spinner display={!!loadingDownload} />
            Pdf
          </DownlopadButton>
        </CharacterInputContainer>

        <CharacterTextStyleSelector
          settextconfig={settextconfig}
          uploadSelect={(uploadSelect: any) => setbannerimage(uploadSelect)}
          uploadSelectSticker={(uploadSelect: any) => setstickerimage(uploadSelect)}
        />
        <DropdownTabComponent
          onBannerSelect={setBannerSelect}
          uploadSelect={(uploadSelect: any) => setbannerimage(uploadSelect)}
        />
        <BannerContainer>
          <Banners
            bannerimage={bannerImage}
            setbannerimage={setbannerimage}
            bannerSelect={bannerSelect}
          />

          <BannerResult
            characters={character}
            bannerimage={bannerImage}
            textconfig={textconfig}
            stickerimage={stickerimage}
          />
        </BannerContainer>
      </CharacterBannerContainer>
    </CharacterBannerMain>
  );
}

export default CharacterBanner;
