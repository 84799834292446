import React, { useState } from 'react';
import { Tabs, Tab, Menu, MenuItem, Box } from '@mui/material';
import styled from 'styled-components';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import tabs from '../../assets/tabs.json';
const StyledBox = styled(Box)``;

interface TabMenuProps {
  options: any[];
  handleCloseMenu: () => void;
  anchorEl: null | HTMLElement;
  setTabValue: (newValue: any) => void;
}

const TabMenu = ({ options, handleCloseMenu, anchorEl, setTabValue }: TabMenuProps) => {
  const open = Boolean(anchorEl);

  const onSelect = (option: string) => {
    setTabValue(option);
    handleCloseMenu();
  };

  return (
    <Menu
      anchorEl={anchorEl}
      open={open}
      onClose={handleCloseMenu}
      PaperProps={{
        style: {
          maxHeight: 300,
          width: '20ch',
        },
      }}
    >
      {options.map((option, index) => (
        <MenuItem
          onClick={() => onSelect(option)}
          sx={{ whiteSpace: 'normal', wordWrap: 'break-word', width: '100%' }}
          key={index}
        >
          {option}
        </MenuItem>
      ))}
    </Menu>
  );
};

const DropdownTabComponent = (props: any) => {
  const { onBannerSelect } = props;
  const [value, setValue] = useState(0);
  const [anchorEl1, setAnchorEl1] = useState<null | HTMLElement>(null);
  const [anchorEl2, setAnchorEl2] = useState<null | HTMLElement>(null);
  const options1 = Object.keys(tabs['Święta typowe']);
  const options2 = Object.keys(tabs['Różne']);

  const setTabValue = (newValue: any) => {
    onBannerSelect(newValue);
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>, menuNumber: number) => {
    if (menuNumber === 1) {
      setAnchorEl1(event.currentTarget);
    } else {
      setAnchorEl2(event.currentTarget);
    }
  };

  const handleCloseMenu = (menuNumber: number) => {
    if (menuNumber === 1) {
      setAnchorEl1(null);
    } else {
      setAnchorEl2(null);
    }
  };

  return (
    <StyledBox>
      <Tabs value={value} onChange={handleChange}>
        <Tab label={'Kolory'} onClick={() => setTabValue('Kolory')} />
        <Tab label={'Wzory'} onClick={() => setTabValue('Wzory')} />
        <Tab label={'Pory roku'} onClick={() => setTabValue('Pory roku')} />
        <Tab label={'Eleganckie'} onClick={() => setTabValue('Eleganckie')} />
        <Tab
          label={'Święta typowe'}
          onClick={(e) => handleOpenMenu(e, 1)}
          icon={<ExpandMoreIcon />}
          iconPosition="end"
        />
        <Tab
          label={'Różne'}
          onClick={(e) => handleOpenMenu(e, 2)}
          icon={<ExpandMoreIcon />}
          iconPosition="end"
        />
      </Tabs>
      <TabMenu
        options={options1}
        handleCloseMenu={() => handleCloseMenu(1)}
        anchorEl={anchorEl1}
        setTabValue={setTabValue}
      />
      <TabMenu
        options={options2}
        handleCloseMenu={() => handleCloseMenu(2)}
        anchorEl={anchorEl2}
        setTabValue={setTabValue}
      />
    </StyledBox>
  );
};

export default DropdownTabComponent;
