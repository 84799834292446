import styled from '@emotion/styled';

const BannerImageContainer = styled.div`
  position: relative;
  width: 80px;
  border: 1px solid #ccc;
  padding: 2px;
  border-radius: 5px;

  &:hover {
    cursor: pointer;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  }
`;

const Image = styled.img`
  width: 100%;
`;

export default function BannerImage(props: any) {
  const { bannerImage, onClick, heighlight } = props;

  return (
    <BannerImageContainer
      onClick={() => onClick(require(`../../assets/banners/${bannerImage}`))}
      style={{
        boxShadow: heighlight ? 'rgba(74, 185, 58, 0.96) 0px 0px 8px' : '',
      }}
    >
      <Image src={require(`../../assets/banners/${bannerImage}`)} alt="Banner" />
    </BannerImageContainer>
  );
}
